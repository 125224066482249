<template>
  <b-row align-h="center">
    <b-col
      v-if="content.title"
      cols="12"
      md="8"
    >
      <h1 class="display-3 font-weight-bolder text-center text-white">
        {{ content.title }}
      </h1>
    </b-col>

    <b-col
      cols="12"
      md="6"
    >

      <div
        ref="announcementRef"
        class="text-white text-center"
      />

      <p
        v-if="content.text"
        class="text-white text-center"
      >
        {{ content.text }}
      </p>
      <!-- <p>
        🔮紫光谷v.113-最燦爛的煙火🔮
        🐳‍將於:11/06號正式開服!!
        ☂️經驗3倍 ☂️掉寶2倍 ☂️楓幣1倍
        🔥粉專網址:https://reurl.cc/1o15nY
        🔥社團網址:https://reurl.cc/35x28X
        🔥DC網址:https://discord.gg/SCYcM8PKxX
        🔥技能特效:https://youtu.be/qx38q6nPEcs
      </p> -->

      <div
        v-if="content.button"
        class="text-center"
      >
        <b-button
          variant="dark"
          :to="{ name: 'apps-e-commerce-checkout' }"
        >
          遊戲下載
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import { nextTick, computed, ref } from '@vue/composition-api'
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  props: {
    showLoading: {
      type: Boolean,
      required: true,
    },
    showData: {
      type: [Object, null],
      required: true,
    },
    content: {
      type: [Object, null],
      default: () => { },
    },
  },
  setup(props) {
    const { width, height } = useWindowSize()
    const windowSize = computed(() => ({ width: width.value, height: height.value }))

    // 子項目
    const announcementRef = ref(null)
    nextTick(() => {
      announcementRef.value.innerHTML = props.showData.announcement
    })

    return {
      announcementRef,

      windowSize,
    }
  },
}
</script>

<style>
</style>
